export default [
  {
    to: "/office",
    icon: "mdi-briefcase-clock",
    text: "勤怠登録",
    authority: [0, 9]
  },
  {
    to: "/attendance",
    icon: "mdi-file-table",
    text: "勤務表",
    authority: [1, 2, 5, 9]
  },
  {
    to: "/shifttable",
    icon: "mdi-chart-timeline",
    text: "シフト表",
    authority: [1, 2, 5, 9]
  },
  {
    to: "/approval",
    icon: "mdi-file-table",
    text: "承認経路",
    authority: [1, 2, 5, 9]
  },
  {
    to: "/approval-status",
    icon: "mdi-file-table",
    text: "承認状態",
    authority: [1, 2, 5, 9]
  },
  {
    open: true,
    text: "福祉事業部",
    icon: "mdi-human-queue",
    authority: [3, 4, 5, 9],
    children: [
      {
        to: "/welfare/attendance-import",
        icon: "mdi-import",
        text: "リライ勤怠取込",
        authority: [4, 9]
      },
      {
        to: "/welfare/attendance",
        icon: "mdi-file-table",
        text: "勤務実績",
        authority: [3, 4, 5, 9]
      },
      {
        to: "/welfare/shifttable",
        icon: "mdi-chart-timeline",
        text: "シフト表",
        authority: [3, 4, 9]
      },
      {
        to: "/welfare/meeting",
        icon: "mdi-account-group",
        text: "ミーティング登録",
        authority: [3, 4, 9]
      },
      {
        to: "/welfare/training",
        icon: "mdi-book-education",
        text: "研修登録",
        authority: [3, 4, 9]
      },
      {
        to: "/welfare/for-calc",
        icon: "mdi-calculator",
        text: "一括給与計算",
        authority: [3, 4, 5, 9]
      },
      {
        to: "/list/rely-to-shain",
        icon: "mdi-database",
        text: "リライ社員変換",
        authority: [4, 9]
      },
      {
        to: "/list/rely-to-shozoku",
        icon: "mdi-database",
        text: "リライ事業所変換",
        authority: [4, 9]
      }
    ]
  },
  {
    to: "/list/shift-pattern",
    icon: "mdi-tune",
    text: "シフトパターン",
    authority: [1, 2, 3, 4, 5, 9]
  },
  {
    to: "/list/daily-event",
    icon: "mdi-calendar-month-outline",
    text: "イベント",
    authority: [1, 2, 3, 4, 5, 9]
  },
  {
    to: "/shift-order",
    icon: "mdi-order-numeric-ascending",
    text: "シフト表示順",
    authority: [1, 2, 3, 4, 5, 9]
  },
  {
    to: "/shift-pattern-order",
    icon: "mdi-order-numeric-descending",
    text: "パターン表示順",
    authority: [1, 2, 3, 4, 5, 9]
  },
  {
    to: "/for-calc",
    icon: "mdi-calculator",
    text: "一括給与計算",
    authority: [1, 2, 5, 9]
  },
  {
    to: "/authinfo",
    icon: "mdi-account-multiple",
    text: "ログインアカウント",
    authority: [2, 4, 5, 9]
  },
  {
    open: false,
    text: "マスタメンテ",
    icon: "mdi-database-cog",
    authority: [1, 2, 3, 4, 5, 9],
    children: [
      {
        to: "/list/code-master",
        icon: "mdi-database",
        text: "コードマスタ",
        authority: [2, 3, 4, 5, 9]
      },
      {
        to: "/list/shozoku-exp",
        icon: "mdi-database",
        text: "所属拡張マスタ",
        authority: [1, 2, 3, 4, 5, 9]
      },
      {
        to: "/list/shain-exp",
        icon: "mdi-database",
        text: "社員拡張マスタ",
        authority: [1, 2, 3, 4, 5, 9]
      },
      {
        to: "/shift-lock",
        icon: "mdi-lock",
        text: "シフト表ロック",
        authority: [4, 9]
      },
      {
        to: "/transportation-expenses",
        icon: "mdi-car",
        text: "交通費マスタ",
        authority: [4, 9]
      }
    ]
  },
  {
    open: false,
    text: "QRコード発行",
    icon: "mdi-qrcode-scan",
    authority: [1, 2, 3, 4, 5, 9],
    children: [
      {
        to: "/qr-shozoku",
        icon: "mdi-qrcode",
        text: "事業所QRコード",
        authority: [1, 2, 3, 4, 5, 9]
      },
      {
        to: "/qr-shain",
        icon: "mdi-qrcode",
        text: "社員QRコード",
        authority: [1, 2, 3, 4, 5, 9]
      }
    ]
  },
  {
    open: false,
    text: "システム管理",
    icon: "mdi-account-cowboy-hat",
    authority: [9],
    children: [
      {
        to: "/list/code-group",
        icon: "mdi-database",
        text: "コードグループ",
        authority: [9]
      },
      {
        text: "デバッグ",
        icon: "mdi-vuejs",
        to: "/debug",
        authority: [9]
      }
    ]
  },
  {
    text: "ログアウト",
    icon: "mdi-logout",
    to: "/login",
    authority: [0, 1, 2, 3, 4, 5, 9]
  }
];
