import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "./user";
import LoginResponse from "@/models/LoginResponse";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login: false,
    title: "",
    name: "",
    type: 0,
    authority: "",
    token: null,
    mtoken: null,
    incode: "",
    userincode: "",
    permitFixTime: "",
    shozokuCode1: "",
    baseShozoku: "",
    hadMeal: ""
  },
  mutations: {
    async login(state: any, payload: LoginResponse) {
      console.log("payload", payload);
      if (payload) {
        state.name = payload.name;
        state.authority = payload.authority;
        state.token = payload.token;
        state.mtoken = payload.mtoken;
        state.incode = payload.incode;
        state.userincode = payload.userincode;
        state.login = true;
        state.permitFixTime = payload.permitFixTime;
        state.shozokuCode1 = payload.shozokuCode1;
        state.baseShozoku = payload.baseShozoku;
        state.hadMeal = payload.hadMeal;
      }
    },
    logout(state: any) {
      console.log("logout");

      state.login = false;
      state.name = "";
      state.type = 0;
      state.authority = "";
      state.token = null;
      state.mtoken = null;
      state.incode = "";
      state.userincode = "";
      state.permitFixTime = "";
      state.shozokuCode1 = "";
      state.baseShozoku = "";
    }
  },
  actions: {},
  modules: { user },
  plugins: [
    createPersistedState({
      key: "aries",
      paths: ["token"],
      storage: window.localStorage
    }),
    createPersistedState({
      key: "aries_manager",
      paths: ["mtoken"],
      storage: window.localStorage
    })
  ]
});
