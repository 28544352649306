import Vue from "vue";
import axios, { AxiosResponse } from "axios";
import Login from "@/models/Login";
import LoginResponse from "@/models/LoginResponse";

import Store from "@/store/index";

// const BaseURL = "http://localhost:8080/aries/api";
// const BaseURL = "https://www.hgswp01.com/aries/api";
const BaseURL = "https://cross-service-webat.com/aries/api";

function getToken() {
  return Store.state.token;
}

function getManagerToken() {
  return Store.state.mtoken;
}

function createHttpClient() {
  return axios.create({
    baseURL: BaseURL,
    headers: {
      authorization: getToken(),
      UserAuthorization: getManagerToken()
    }
  });
}

function createDownloadExcelHttpCLient() {
  return axios.create({
    baseURL: BaseURL,
    headers: {
      authorization: getToken(),
      UserAuthorization: getManagerToken(),
      "Content-Type": "application/zip"
    },
    responseType: "blob"
  });
}

function getUrl(baseUrl: string, params: string | string[]): string {
  // const param = params
  //   ? params instanceof String
  //     ? params
  //     : (params as string[]).join("/")
  //   : "";
  const param = params ? params : "";
  const url = param
    ? baseUrl + (baseUrl.match("^.*/$") ? "" : "?") + param
    : baseUrl + (baseUrl.match("^.*/$") ? "" : "/");
  return url;
}

const Paths = {
  atuhShozoku: "/auth/shozoku",
  authManager: "/auth/manager",
  authTemporary: "/auth/temporary",
  attendance: "/attendance",
  attendanceFix: "/attendance/fix",
  attendanceStart: "/attendance/start",
  attendanceEnd: "/attendance/end",
  attendanceCalcTime: "/attendance/calc",
  attendanceCalc: "/attendance/salarycalc",
  attendanceSalaryFix: "/attendance/salaryfix",
  attendanceMultiSalaryCalc: "/attendance/multisalarycalc",
  attendanceSalaryConfirm: "/attendance/salary-confirm",
  attendanceSalaryConfirmcheck: "/attendance/salary-confirm-check",
  attendanceBulk: "/attendance/bulk",
  shain1: "/daijin/shain1",
  shozoku: "/daijin/shozoku",
  shiftUpsert: "shift/upsert",
  shiftpattern: "/shiftpattern",
  shiftpatternPulldown: "/shiftpattern/pull",
  workClassification: "/workc",
  shift: "/shift",
  shiftExcel: "/shift/excel",
  shiftded: "/shift/deleteDed",
  authinfoTemporary: "/authinfo/temporary",
  codeGroup: "/codegroup",
  codeGroupPull: "/codegroup/pull",
  forCalc: "/for-calc",
  forCalcWelfare: "/for-calc/welfare",
  codeMaster: "/code-master",
  codeMasterPulldown: "/code-master/pulldown",
  shozokuQr: "/daijin/shozoku/qr",
  shainQr: "/daijin/shain1/qr",
  shozokuExp: "/shozoku-exp",
  shainExp: "/shain-exp",
  welfareAttendanceImport: "welfare/import",
  authinfo: "/authinfo",
  authinfoBulk: "/authinfo/bulk",
  dailyEvent: "/daily-event",
  shiftOrder: "/shift-order",
  shozokuFilter: "/daijin/shozoku/filter",
  shainFilter: "/daijin/shain1/filter",
  shiftAttendance: "/shift-attendance",
  meetingAdd: "/welfare/meeting",
  tmpDistance: "/welfare/distance",
  relyToShain: "/rely-to-shain",
  relyToShozoku: "/rely-to-shozoku",
  attendanceCalcTimeFukushi: "/attendance/calcfukushi",
  attendanceCalcFukushi: "/attendance/salarycalcfukushi",
  attendanceMultiCalc: "/attendance/calc-multi",
  shainSorted: "/daijin/shain1/sorted",
  shiftPatternOrder: "/shiftpattern/order",
  authNomalUser: "/auth/user",
  attendanceStartSp: "/attendance/sp/start",
  attendanceEndSp: "/attendance/sp/end",
  trainingAdd: "/welfare/training",
  attendanceMultiCalcFukushi: "/attendance/calc-multi-fukushi",
  attendanceMultiSaralyCalcFukushi: "/attendance/multisalarycalc-fukushi",
  approvalList: "/approval",
  approvalStatus: "/approval/status",
  approvalStatusUser: "/approval/status/user",
  shozokuFilterForCalc: "/daijin/shozoku/filter/for-calc",
  shozokuFilterForCalcWelfare: "/daijin/shozoku/filter/for-calc/welfare",
  shiftLock: "/shift-lock",
  transportationExpenses: "/transportation-expenses",
  attendanceapproval: "attendance/approval"
};

export default Vue.extend({
  name: "Api",
  data() {
    return {
      Paths
    };
  },
  methods: {
    async $auth(payload: Login): Promise<any> {
      console.log("auth", payload);

      const data: any = {
        loginId: payload.loginId,
        password: payload.password,
        isQr: payload.isQr ? "1" : "0"
      };

      let path = "";
      switch (payload.selectTab) {
        case 0:
          path = Paths.atuhShozoku;
          break;
        case 1:
          path = Paths.authManager;
          break;
        default:
          path = Paths.authNomalUser;
          break;
      }

      return await this.$post(path, data);
    },
    async $tmpauth(payload: Login): Promise<any> {
      console.log("auth", payload);

      const data: any = {
        loginId: payload.loginId,
        password: payload.password,
        isQr: payload.isQr
      };

      return await this.$post(Paths.authTemporary, data);
    },
    async $verify(payload: Login): Promise<any> {
      console.log("auth", payload);

      const token = getToken();

      if (token) {
        Store.state.name = "ダミー";
        Store.state.login = true;
      }

      return !!token;
    },
    /**
     * Get Method
     * @param {String} path
     * @param {String|String[]} params
     * @param {Object} query
     */
    async $get(
      path: string,
      params: string | string[],
      query: any
    ): Promise<any> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      const result = await client.get(url, { params: query });

      const error = result.data.error;
      if (error) {
        console.error(error);
        throw new Error(error.errorMessage);
      }

      console.log(result);
      return result.data;
    },

    async $downloadexcel(
      path: string,
      params: string | string[],
      query: any
    ): Promise<any> {
      const client = createDownloadExcelHttpCLient();

      const url = getUrl(path, params);

      const result = await client.get(url, { params: query });

      const error = result.data.error;
      if (error) {
        console.error(error);
        throw new Error(error.errorMessage);
      }

      console.log(result);
      return result.data;
    },
    /**
     * POST Method
     * @param {String} path
     * @param {Object} data
     * @param {String|String[]} params
     */
    async $post(
      path: string,
      data: any,
      params: string | string[] = ""
    ): Promise<any> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      console.log("POST: " + url + " &" + JSON.stringify(data));

      const result = await client.post(url, data);

      const error = result.data.error;
      if (error) {
        console.error(error);
        throw new Error(error.errorMessage);
      }

      console.log(result);
      return result.data;
    },
    /**
     * PUT Method
     * @param {String} path
     * @param {Object} data
     * @param {String|String[]} params
     */
    async $put(
      path: string,
      data: any,
      params: string | string[]
    ): Promise<any> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      console.log("PUT: " + url + " &" + JSON.stringify(data));

      const result = await client.put(url, data);

      const error = result.data.error;
      if (error) {
        console.error(error);
        throw new Error(error.errorMessage);
      }

      console.log(result);
      return result.data;
    },

    /**
     * DELETE Method
     * @param {String} path
     * @param {String|String[]} params
     * @param {Object} query
     */
    async $del(
      path: string,
      params: string | string[],
      query: any
    ): Promise<any> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      console.log("DELETE: " + url + "?" + JSON.stringify(query));

      const result = await client.delete(url, { params: query });

      const error = result.data.error;
      if (error) {
        console.error(error);
        throw new Error(error.errorMessage);
      }

      console.log(result);
      return result.data;
    }
  }
});
